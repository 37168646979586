/*
|--------------------
|      SINGLE
|--------------------
*/

#page-news-single {
  .nickelback {
    margin: 80px 0 50px;
  }
  a.link-arrow, .link.link-arrow {
    color: #0a1c40;
    font-family: "Graphik";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 2px;
    line-height: normal;
    text-align: left;
    text-decoration: underline;
    &:before {
      content: '';
      display: inline-block;
      margin-right: 24px;
      width: 20px;
      height: 5px;
      vertical-align: middle;
      background-image: url(../img/icon/link-arrow.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .news-single {
    .news-single-date {
      font-family: "Graphik", sans-serif;
      font-weight: 200;

      margin: 0 0 40px;

      .news-single-day {
        color: #8eb3dd;
        font-size: 40px;
        line-height: 35px;
      }

      .news-single-year {
        font-size: 12px;
      }
    }

    .news-single-category {
      color: #8eb3dd;
      font-family: "Graphik", sans-serif;
      font-size: 24px;
      font-weight: 200;

      margin: 0 0 40px;
    }

    .news-single-title {
      font-family: "Canela";
      font-size: 46px;
    }

    .news-single-excerpt {
      font-family: "Graphik", sans-serif;
      font-size: 32px;
      font-weight: 200;

      margin: 0 0 50px;
    }

    .news-single-share {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .news-single-share-item {
        margin: 0 0 25px;
      }
    }

    .news-single-content {
      margin: {
        top: 50px;
        bottom: 160px;
      }

      .cms-news {
        font-size: 24px;

        h1, h2, h3, h4 {
          font-family: "Canela";
          font-weight: 400;

          margin: 60px 0 30px;
        }

        p {
          margin: 0 0 30px;
          color: #141414;
          font-family: "Graphik";
          font-size: 24px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
          line-height: 32px;
          text-align: left;

        }

        strong, b {
          font-weight: 500;
        }

        blockquote {
          position: relative;

          margin: 90px auto 110px;

          max-width: 720px;

          padding: 0;

          p{
            font-family: "Graphik";
            font-size: 30px;
            line-height: 38px;
            font-weight: 400!important;
            font-style: italic;
          }

          a{
            text-decoration: underline!important;
          } 

          &, p {
            font-family: "Graphik";
            font-size: 30px;
            font-weight: 200;
          }

          &:before {
            content: '';

            position: absolute;
            top: -30px;
            left: -30px;

            width: 30px;
            height: 30px;

            margin: {
              right: 15px;
            }

            background-image: url('../img/icon/quote.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 30px 30px;
          }

          &:after {
            content: '';

            position: absolute;
            bottom: -30px;
            right: 0;

            width: 30px;
            height: 30px;

            background-image: url('../img/icon/quote.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 30px 30px;

            transform: rotate(180deg);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .news-single {
      .news-single-share {
        flex-direction: row;

        .news-single-share-item {
          margin: 0 25px 50px;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .news-single-content {
        .cms {
          blockquote {
            margin: 90px 30px 110px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .news-single {
      .news-single-share {

        .news-single-share-item {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
