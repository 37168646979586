/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*{
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body{
    font-family: $font-family-default;
    color: $default-color;
    font-size: $default-font-size;
    line-height: $default-line-height;
    font-weight: $default-font-weight;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
  @include media-breakpoint-down(md) {
    font-style: 14px;
  }
}

h1, h2, h3, h4, h5, h6{
    @each $property, $value in $default-titles{
        #{$property}: $value;
    }
}

button, input, textarea{
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}

button{
    -webkit-appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
}

.flex{
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.txt-r{
  text-align:right;
}

.txt-c{
  text-align:center;
}

.clearfix{
  clear: both;
}

img{
  max-width: inherit;
}

a, img, span, button{
    display: inline-block;
}

a{
    color:$default-link-color;
    text-decoration: none;

    &:hover{
        color: $default-link-color;
    }
}

ul{
    margin: 0;
    padding: 0;
    
    li{
        list-style-type: none;
    }
}

.nopad{
  padding:0;
}

.site-container{
  overflow: hidden;
}

.scrollToTop{
  width: 40px;
  height: 40px;
  background-color: #8EB3DD;
  border-radius: 100%;
  position: fixed;
  font-size: 24px;
  color: #fff;
  border: none;
  right: 30px;
  bottom: 30px;
  display: none;
  cursor:pointer;
  img{
    margin-bottom: 10px;
    width: 50%;
    transition:all ease 0.3s;
  }
  &:hover{
    img{
      margin-bottom: 15px;
      transition:all ease 0.3s;
    }
  }
}

.cookie-banner{
  display: none!important;
}