/*
|--------------------
|      NEWS
|--------------------
*/

.load-more-container {

  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  .loader-container {
    display: none;
  }
}

.cpt-header {
  background-color: #F6F7FC;
  padding: 200px 0 90px 0;

  .cpt-header-title {
    font-size: 64px;
    font-family: "Canela", sans-serif!important;
    @include media-breakpoint-down(sm) {
      font-size: 46px;
    }
  }

  .cpt-filter-container {
    margin: 35px 0 0;

    &.open {
      .filter-toggle {
        &:after {
          transform: rotate(0deg);
        }
      }

      .filter-items-container {
        display: block;
      }
    }

    .filter-toggle {
      display: flex;
      align-items: center;
      font-family: "Canela", sans-serif;
      font-size: 32px;
      font-weight: 300;

      cursor: pointer;

      &:after {
        content: '';

        width: 5px;
        height: 20px;

        margin: {
          left: 30px;
        }

        background-image: url('../img/icon/arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        transform: rotate(180deg);
      }
    }

    .filter-items-container {
      display: none;

      margin: 25px 0 0;

      .filter-items {
        color: #8A94A4;
        font-size: 24px;
        font-weight: 100;

        margin: 0 0 20px;

        cursor: pointer;

        &.active {
          color: #113D59;
          font-weight: 500;
        }

        &.has-childs {
          &.open {
            display: flex;
            align-items: flex-start;

            color: #8eb3dd;

            .filter-name {
              display: flex;
              align-items: center;

              &:after {
                content: '';

                width: 8px;
                height: 8px;

                margin: {
                  left: 25px;
                }

                border: {
                  top: 1px solid #8eb3dd;
                  right: 1px solid #8eb3dd;
                }

                transform: rotate(45deg);
              }
            }

            .filter-childs {
              display: block;

              margin: {
                left: 150px;
              }

              @include media-breakpoint-down(sm) {
                margin: {
                  left: 20px;
                }
              }
            }
          }
        }

        .filter-childs {
          display: none;

          .filter-items {
          }
        }
      }

      .filter-items-separator {
        width: 100%;
        height: 1px;

        margin: 0 0 20px;

        background-color: rgba(#113D59, .5);
      }
    }
  }
}

.news-list {
  .news-line {
    display: block;

    color: #0a1c40;

    margin: 0 0 50px;
    padding: 0 0 50px;

    border-bottom: 1px solid rgba(#0a1c40, .3);

    &:last-child {
      border: none;

      margin: 0;
    }

    .news-date {
      font-family: "Graphik";
      font-weight: 200;

      .news-day {
        color: #8eb3dd;
        font-size: 40px;
        line-height: 35px;
      }

      .news-year {
        font-size: 12px;
      }
    }

    .news-activity {
      color: #8eb3dd;
      font-family: "Graphik";
      font-size: 13px;
      font-weight: 200;
      margin: 10px 0;
    }

    .news-title {
      font-family: "Graphik";
      font-size: 20px;
      font-weight: 400;
      margin: 0 0 30px;
    }
  }

  @include media-breakpoint-down(sm) {
    .news-line {
      .news-title {
        font-size: 24px;
        margin: 0;
      }
    }
  }
}