/*
|--------------------
|     PAGE HOME
|--------------------
*/

.home {
  .bigtitle {
    font-family: Canela;
    font-size: 42px;
    font-weight: 400;
    line-height: 38px;
    @include media-breakpoint-down(md) {
      visibility: visible;
      text-align: center;
      font-size: 32px;
      line-height: 26px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 22px;
      line-height: initial;
    }
    @include media-breakpoint-down(md) {
      span {
        width: 100%;
        padding-top: 10px;
      }
    }
    img {
      margin-top: -10px;
    }
  }

  #vita-lbo {
    background-color: #063d58;
    color: #f7f7f7;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    h1 {
      color: #8eb3dd;
    }
    strong {
      color: #8eb3dd;
    }
    a {
      color: #8eb3dd;
    }
    .bigtitle {
      color: #f7f7f7;
      font-family: Canela;
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        margin: auto;
      }
    }
    #lbofrance {
      img {
        max-width: 60px;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }

  #finanziamento {
    .intro-logo-block {
      width: 30%;
      float: left;
      height: 500px;
      @include media-breakpoint-down(md) {
        width: initial;
        max-width: 100%;
        padding: 0px;
        height: initial;
        margin: auto;
        float: initial;
      }
      img {
        width: initial;
        max-width: 100%;
        padding: 15px;
        @include media-breakpoint-down(md) {
          width: 300px;
          margin: auto;
        }
        @include media-breakpoint-down(sm) {
          width: 150px;
          margin: auto;
        }
      }
    }
    .detail {
      float: left;
      width: 70%;
      height: 500px;
      @include media-breakpoint-down(md) {
        width: initial;
        max-width: 100%;
        height: initial;
        float: initial;
        margin-top: 15px;
      }
    }
  }

  #newshome {
    background-color: #f6f7fc;

    .link-home {
      color: #0a1c40;
      font-family: Canela;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 2px;
      line-height: normal;
      text-align: center;
      text-decoration: underline;
    }
  }

  #modulo {
    background-color: #f4f4f5;
    .gform_body {
      display: flex;
      @include media-breakpoint-down(md) {
        display: block;
      }
      .gform_fields {
        width: 50%;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
      .col2-form {
        width: 50%;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
      label {
        font-weight: 500;
      }
    }

    input {
      width: 100%;
      border: 1px solid;
    }
    select {
      width: 100%;
      border: 1px solid;
      padding: 7px 7px;
    }
    input[type="file"] {
      border: none;
    }
    input[type="submit"] {
      color: #ffffff;
      font-family: Graphik;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      background-color: #8eb3dd;
      border: 1px solid #8eb3dd;
      padding: 17px 0 15px 0;
      float: right;
      width: 160px;
      cursor: pointer;
      transition: all ease 0.3s;
      &:hover {
        background-color: transparent;
        color: #8eb3dd;
        border: 1px solid #8eb3dd;
        transition: all ease 0.3s;
      }
      @include media-breakpoint-down(md) {
        float: initial;
        margin: auto;
        display: block;
      }
    }
    .from-desc {
      margin-top: 15px;
      p {
        line-height: initial;
      }
    }
  }

}