/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 25px 0;
  background-color: #143e58;
  color: #fff;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  @include media-breakpoint-down(md) {
    text-align: center;
    .txt-r{
      text-align: center;
    }
  }
  a {
    color: #fff;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}